// extracted by mini-css-extract-plugin
export var wrapper = "mainConvictionArea-module--wrapper--2D4Z1";
export var imageSection = "mainConvictionArea-module--imageSection--1X0D-";
export var image = "mainConvictionArea-module--image--2NaqC";
export var decoration = "mainConvictionArea-module--decoration--35VE5";
export var decoration0 = "mainConvictionArea-module--decoration0--Ozl4k";
export var decoration1 = "mainConvictionArea-module--decoration1--sTA4W";
export var decoration2 = "mainConvictionArea-module--decoration2--2s7rk";
export var decoration3 = "mainConvictionArea-module--decoration3--2kvVy";
export var content = "mainConvictionArea-module--content--3p24f";
export var heading = "mainConvictionArea-module--heading--1oSaO";
export var description = "mainConvictionArea-module--description--3awEF";
export var button = "mainConvictionArea-module--button--24GqK";
export var buttonLink = "mainConvictionArea-module--buttonLink--2gYs0";