import React from 'react';
import * as PropTypes from 'prop-types';

import GeneralLayout from '../layouts/index';
import Seo from '../components/SEO';
import Theme from '../../src/components/Theme';
import Newsletter from '../components/Newsletter';
import MainConvictionAreas from '../components/ConvictionAreasPage/MainConvictionAreas';
import { graphql } from 'gatsby';
import MinorConvictionAreas from '../components/ConvictionAreasPage/MinorConvictionAreas';

const ConvictionAreasPageTemplate = ({ transition, data }) => {
  const { contentfulConvictionAreas, contentfulNewsletter, site } = data;

  return (
    <GeneralLayout>
      <Theme theme="emerald" transition={transition}>
        <Seo
          siteData={site}
          seoData={contentfulConvictionAreas.seo}
          defaultTitle="Emergence - Theses"
          pathName="/conviction-areas"
        />
        <MainConvictionAreas content={contentfulConvictionAreas} />
        <MinorConvictionAreas content={contentfulConvictionAreas} />
        <Newsletter content={contentfulNewsletter} />
      </Theme>
    </GeneralLayout>
  );
};

ConvictionAreasPageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ConvictionAreasPageTemplate;

export const query = graphql`
  query ConvictionAreas {
    contentfulConvictionAreas {
      ...MainConvictionAreasQuery
      ...MinorConvictionAreasQuery
      seo {
        customTitle
      }
    }
    ...NewsletterQuery
    ...SiteQuery
  }
`;
