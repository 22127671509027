import React from 'react';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';

import * as styles from './mainConvictionArea.module.scss';

const MainConvictionArea = ({ imgSrc, imgAlt, title, slug, decorationNumber, description }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.imageSection}>
        {imgSrc ? (
          <img className={styles.image} src={imgSrc} alt={imgAlt} />
        ) : (
          <div className={styles.image}></div>
        )}
        <img
          src={`/images/convictionAreas/${slug}-decoration.svg`}
          alt=""
          className={[styles.decoration, styles[`decoration${decorationNumber}`]].join(' ')}
        />
      </div>
      <div className={styles.content}>
        <h2 className={styles.heading}>{title}</h2>
        <p className={styles.description}>{description}</p>
        <button className={styles.button}>
          <Link to={`/conviction-areas/${slug}`} className={styles.buttonLink}>
            Learn more
          </Link>
        </button>
      </div>
    </div>
  );
};

MainConvictionArea.propTypes = {
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  title: PropTypes.string,
  slug: PropTypes.string,
  decorationNumber: PropTypes.number,
  description: PropTypes.string,
};

export default MainConvictionArea;
