import React from 'react';
import * as PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import MinorConvictionArea from '../MinorConvictionArea';

import * as styles from './minorConvictionAreas.module.scss';

const MinorConvictionAreas = ({ content }) => {
  const { minorConvictionAreasHeadline, minorConvictionAreasDescription, minorConvictionAreas } =
    content;

  return (
    <section className={styles.wrapper}>
      <div className={styles.content}>
        {minorConvictionAreasHeadline && (
          <h1 className={styles.headline}>{minorConvictionAreasHeadline}</h1>
        )}
        {minorConvictionAreasDescription && (
          <p className={styles.overview}>
            {minorConvictionAreasDescription.minorConvictionAreasDescription}
          </p>
        )}
        {minorConvictionAreas && (
          <div className={styles.convictionAreas}>
            {minorConvictionAreas.map(minorConvictionArea => {
              return (
                <MinorConvictionArea
                  key={minorConvictionArea.minorConvictionTitle}
                  title={minorConvictionArea.minorConvictionTitle}
                  description={
                    minorConvictionArea.minorConvictionDescription
                      ? minorConvictionArea.minorConvictionDescription.minorConvictionDescription
                      : ''
                  }
                  blogs={minorConvictionArea.relatedBlogs}
                />
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
};

MinorConvictionAreas.propTypes = {
  content: PropTypes.object.isRequired,
};

export default MinorConvictionAreas;

export const query = graphql`
  fragment MinorConvictionAreasQuery on ContentfulConvictionAreas {
    minorConvictionAreas {
      minorConvictionTitle
      minorConvictionDescription {
        minorConvictionDescription
      }
      relatedBlogs {
        authorName
        title
        url
      }
    }
    minorConvictionAreasHeadline
    minorConvictionAreasDescription {
      minorConvictionAreasDescription
    }
  }
`;
