import React from 'react';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';

import MainConvictionArea from '../MainConvictionArea';

import * as styles from './mainConvictionAreas.module.scss';

const MainConvictionAreas = ({ content }) => {
  const { mainConvictionAreasHeadline, mainConvictionAreasDescription, mainConvictionAreas } =
    content;
  return (
    <section className={styles.convictionAreas}>
      <div className={styles.content}>
        {mainConvictionAreasHeadline && (
          <h1 className={styles.headline}>{mainConvictionAreasHeadline}</h1>
        )}
        {mainConvictionAreasDescription && (
          <p className={styles.overview}>
            {mainConvictionAreasDescription.mainConvictionAreasDescription}
          </p>
        )}
        {mainConvictionAreas &&
          mainConvictionAreas.map((convictionArea, id) => {
            return (
              <MainConvictionArea
                key={convictionArea.slug}
                imgSrc={convictionArea.image ? convictionArea.image.fluid.src : ''}
                imgAlt={convictionArea.image ? convictionArea.image.title : ''}
                title={convictionArea.mainConvictionHeadline}
                slug={convictionArea.slug}
                decorationNumber={id}
                description={
                  convictionArea.mainConvictionDescription
                    ? convictionArea.mainConvictionDescription.mainConvictionDescription
                    : ''
                }
              />
            );
          })}
      </div>
    </section>
  );
};

MainConvictionAreas.propTypes = {
  content: PropTypes.object.isRequired,
};

export default MainConvictionAreas;

export const query = graphql`
  fragment MainConvictionAreasQuery on ContentfulConvictionAreas {
    mainConvictionAreasHeadline
    mainConvictionAreasDescription {
      mainConvictionAreasDescription
    }
    mainConvictionAreas {
      mainConvictionHeadline
      slug
      mainConvictionDescription {
        mainConvictionDescription
      }
      image {
        fluid {
          src
        }
        title
      }
    }
  }
`;
