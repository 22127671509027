import React from 'react';
import * as PropTypes from 'prop-types';

import * as styles from './minorConvictionArea.module.scss';

const MinorConvictionArea = ({ title, description, blogs }) => {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.heading}>{title}</h2>
      <p className={styles.description}>{description}</p>
      <h3 className={styles.headingList}>Related thoughts:</h3>
      <ul className={styles.list}>
        {blogs.map(blog => {
          return (
            <li className={styles.listItem} key={blog.title}>
              <a href={blog.url}>{blog.title},</a>
              <span className={styles.blogDetails}>{blog.authorName}</span>
              {blog.date && <span className={styles.blogDetails}>{blog.date}</span>}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

MinorConvictionArea.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  blogs: PropTypes.array,
};

export default MinorConvictionArea;
